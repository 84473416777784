import {reactive, toRefs, watch} from "vue";
import service from "@/utils/axios";
import {onPageLoadingEnd, onPageLoadingStart} from "@/utils/DLoading";
import {useRouter} from "vue-router";

export interface ICourseSelectState{
  version: string;
  subject: string;
  versionList: Array<string>;
  subjectList: Array<string>;
  courseList: Array<ICourse>;
}

export interface ICourse{
  id: string|null;
  classTime: string|null;
  lessonName: string|null;
  version: string|null;
}

export function courseSelectBill() {
  let stateRef = reactive(<ICourseSelectState>{
    version: '3.0',
    subject: '',
    versionList: [],
    subjectList: [],
    courseList: [],
  })

  let refData = toRefs(stateRef)

  const router = useRouter();

  const init = async () => {
    onPageLoadingStart('正在获取数据')
    await getAllVersion()
    await getAllSubject()
    onPageLoadingEnd();
  }

  const getAllVersion = async () => {
    const r:Array<string> = await service.get('/dwart/an_teaching/home/version/all')
    stateRef.versionList = r;
  }

  const getAllSubject = async () => {
    const r:Array<string> = await service.get('/dwart/an_teaching/home/subject/all')
    stateRef.subjectList = r;
    stateRef.subject = r[0]
  }

  const getCourseByVersionAndSubject = async () => {
    const r:any = await service.post(`/dwart/an_teaching/home/jump/${2}`, {
      subjectName: stateRef.subject,
      version: stateRef.version,
    })
    stateRef.courseList = r
  }

  const onCourseClick = async (item: ICourse) => {
    await router.push({
      name: 'CourseTopic',
      query: {
        id: item.id,
        name: item.lessonName,
      }
    })
  }

  watch(()=> stateRef.version,async ()=>{
    await getCourseByVersionAndSubject()
  })

  watch(()=> stateRef.subject, async ()=> {
    await getCourseByVersionAndSubject()
  })
  return {
    refData,
    init,
    onCourseClick,
    getCourseByVersionAndSubject,
  }
}
